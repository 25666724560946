{
    var autoInterval;
    var hand_stop = false;
}
jQuery(document).ready(function () {
    /*
     convert a cubic bezier value to a custom mina easing
     */
    var duration = 200,
        delay = 150,
        epsilon = (1000 / 60 / duration) / 4,
        firstCustomMinaAnimation = bezier(.42, .03, .77, .63, epsilon),
        secondCustomMinaAnimation = bezier(.27, .5, .6, .99, epsilon);

    //initialize the slider
    $('.cd-slider-wrapper').each(function () {
        initSlider($(this));
    });
    function initSlider(sliderWrapper) {
        //cache jQuery objects
        var slider = sliderWrapper.find('.cd-slider'),
            sliderNavigation = sliderWrapper.find('.cd-slider-navigation').find('li'),
            svgCoverLayer = sliderWrapper.find('div.cd-svg-cover'),
            sliderNav = sliderWrapper.find('.cd-slider-navigation'),
            sliderControls = sliderWrapper.find('.cd-slider-controls').find('li');
        pathId = svgCoverLayer.find('path').attr('id'),
            svgPath = Snap('#' + pathId);
        //store path 'd' attribute values
        var pathArray = [];
        pathArray[0] = svgCoverLayer.data('step1');
        pathArray[1] = svgCoverLayer.data('step6');
        pathArray[2] = svgCoverLayer.data('step2');
        pathArray[3] = svgCoverLayer.data('step7');
        pathArray[4] = svgCoverLayer.data('step3');
        pathArray[5] = svgCoverLayer.data('step8');
        pathArray[6] = svgCoverLayer.data('step4');
        pathArray[7] = svgCoverLayer.data('step9');
        pathArray[8] = svgCoverLayer.data('step5');
        pathArray[9] = svgCoverLayer.data('step10');

        autoInterval = setInterval(function () {
            nextSlide(slider, svgCoverLayer, sliderControls, pathArray, svgPath);
        }, 7000);
        //update visible slide when user clicks next/prev arrows
        sliderNav.on('click', '.next-slide', function (event) {
            event.preventDefault();
            nextSlide(slider, svgCoverLayer, sliderControls, pathArray, svgPath);
            hand_stop = true;
        });
        sliderNav.on('click', '.prev-slide', function (event) {
            event.preventDefault();
            prevSlide(slider, svgCoverLayer, sliderControls, pathArray, svgPath);
            hand_stop = true;
        });

        //detect swipe event on mobile - update visible slide
        slider.on('swipeleft', function (event) {
            nextSlide(slider, svgCoverLayer, sliderControls, pathArray, svgPath);
            hand_stop = true;
        });
        slider.on('swiperight', function (event) {
            prevSlide(slider, svgCoverLayer, sliderControls, pathArray, svgPath);
            hand_stop = true;
        });

        //update visible slide when user clicks .cd-slider-navigation buttons
        sliderControls.on('click', function (event) {
            event.preventDefault();
            var selectedItem = $(this);
            if (!selectedItem.hasClass('selected')) {
                hand_stop = true;
                // if it's not already selected
                var selectedSlidePosition = selectedItem.index(),
                    selectedSlide = slider.children('li').eq(selectedSlidePosition),
                    visibleSlide = retrieveVisibleSlide(slider),
                    visibleSlidePosition = visibleSlide.index(),
                    direction = '';
                direction = ( visibleSlidePosition < selectedSlidePosition) ? 'next' : 'prev';
                updateSlide(visibleSlide, selectedSlide, direction, svgCoverLayer, sliderControls, pathArray, svgPath);
            }
        });
    }

    function nextSlide(slider, svgCoverLayer, sliderNavigation, paths, svgPath) {
        var visibleSlide = retrieveVisibleSlide(slider),
            nextSlide_el = ( visibleSlide.next('li').length > 0 ) ? visibleSlide.next('li') : slider.find('li').eq(0);
        updateSlide(visibleSlide, nextSlide_el, 'next', svgCoverLayer, sliderNavigation, paths, svgPath);
    }

    function retrieveVisibleSlide(slider, sliderControls, pathArray) {
        return slider.find('.visible');
    }

    function prevSlide(slider, svgCoverLayer, sliderNavigation, paths, svgPath) {
        var visibleSlide = retrieveVisibleSlide(slider),
            prevSlide = ( visibleSlide.prev('li').length > 0 ) ? visibleSlide.prev('li') : slider.find('li').last();
        updateSlide(visibleSlide, prevSlide, 'prev', svgCoverLayer, sliderNavigation, paths, svgPath);
    }

    function updateSlide(oldSlide, newSlide, direction, svgCoverLayer, controls, paths, svgPath) {
        if (direction == 'next') {
            var path1 = paths[0],
                path2 = paths[2],
                path3 = paths[4];
            path4 = paths[6];
            path5 = paths[8];
        } else {
            var path1 = paths[1],
                path2 = paths[3],
                path3 = paths[5];
            path4 = paths[7];
            path5 = paths[9];
        }

        svgCoverLayer.addClass('is-animating');
        svgPath.attr('d', path1);
        svgPath.animate({'d': path2}, duration, firstCustomMinaAnimation, function () {
            svgPath.animate({'d': path3}, duration, secondCustomMinaAnimation, function () {
                oldSlide.removeClass('visible');
                newSlide.addClass('visible');
                updateNavSlide(newSlide, controls);
                setTimeout(function () {
                    svgPath.animate({'d': path4}, duration, firstCustomMinaAnimation, function () {
                        svgPath.animate({'d': path5}, duration, secondCustomMinaAnimation, function () {
                            svgCoverLayer.removeClass('is-animating');
                        });
                    });
                }, delay);
            });
        });
        if (hand_stop) {
            clearInterval(autoInterval);
            $('.cd-slider-wrapper').each(function () {
                initSlider($(this));
            });
            hand_stop = false;
        }
    }

    function updateNavSlide(actualSlide, controls) {
        var position = actualSlide.index();
        controls.removeClass('selected').eq(position).addClass('selected');
    }

    function bezier(x1, y1, x2, y2, epsilon) {
        //https://github.com/arian/cubic-bezier
        var curveX = function (t) {
            var v = 1 - t;
            return 3 * v * v * t * x1 + 3 * v * t * t * x2 + t * t * t;
        };

        var curveY = function (t) {
            var v = 1 - t;
            return 3 * v * v * t * y1 + 3 * v * t * t * y2 + t * t * t;
        };

        var derivativeCurveX = function (t) {
            var v = 1 - t;
            return 3 * (2 * (t - 1) * t + v * v) * x1 + 3 * (-t * t * t + 2 * v * t) * x2;
        };

        return function (t) {

            var x = t, t0, t1, t2, x2, d2, i;

            // First try a few iterations of Newton's method -- normally very fast.
            for (t2 = x, i = 0; i < 8; i++) {
                x2 = curveX(t2) - x;
                if (Math.abs(x2) < epsilon) return curveY(t2);
                d2 = derivativeCurveX(t2);
                if (Math.abs(d2) < 1e-6) break;
                t2 = t2 - x2 / d2;
            }

            t0 = 0, t1 = 1, t2 = x;

            if (t2 < t0) return curveY(t0);
            if (t2 > t1) return curveY(t1);

            // Fallback to the bisection method for reliability.
            while (t0 < t1) {
                x2 = curveX(t2);
                if (Math.abs(x2 - x) < epsilon) return curveY(t2);
                if (x > x2) t0 = t2;
                else t1 = t2;
                t2 = (t1 - t0) * .5 + t0;
            }

            // Failure
            return curveY(t2);

        };
    }
});
